div[data-block="tripetto-block-text"] h2 label span, div[data-block="tripetto-block-textarea"] h2 label span, div[data-block="tripetto-block-number"] h2 label span, div[data-block="tripetto-block-multiple-choice"] h2 label span, div[data-block="tripetto-block-dropdown"] h2 label span, div[data-block="tripetto-block-paragraph"] h2 label span {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
}

div[data-block="tripetto-block-text"] h3 span, div[data-block="tripetto-block-textarea"] h3 span, div[data-block="tripetto-block-number"] h3 span, div[data-block="tripetto-block-multiple-choice"] h3 span, div[data-block="tripetto-block-dropdown"] h3 span, div[data-block="tripetto-block-paragraph"] h3 span {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;
}

div[data-block="tripetto-block-text"] button, div[data-block="tripetto-block-text"] select, div[data-block="tripetto-block-text"] input, div[data-block="tripetto-block-text"] textarea, div[data-block="tripetto-block-textarea"] button, div[data-block="tripetto-block-textarea"] select, div[data-block="tripetto-block-textarea"] input, div[data-block="tripetto-block-textarea"] textarea, div[data-block="tripetto-block-number"] button, div[data-block="tripetto-block-number"] select, div[data-block="tripetto-block-number"] input, div[data-block="tripetto-block-number"] textarea, div[data-block="tripetto-block-multiple-choice"] button, div[data-block="tripetto-block-multiple-choice"] select, div[data-block="tripetto-block-multiple-choice"] input, div[data-block="tripetto-block-multiple-choice"] textarea, div[data-block="tripetto-block-dropdown"] button, div[data-block="tripetto-block-dropdown"] select, div[data-block="tripetto-block-dropdown"] input, div[data-block="tripetto-block-dropdown"] textarea, div[data-block="tripetto-block-paragraph"] button, div[data-block="tripetto-block-paragraph"] select, div[data-block="tripetto-block-paragraph"] input, div[data-block="tripetto-block-paragraph"] textarea {
  border-color: #0006;
  border-radius: 5px;
  width: 100%;
  padding: .25rem .5rem .2rem;
}

div[data-block="tripetto-block-text"] nav button, div[data-block="tripetto-block-textarea"] nav button, div[data-block="tripetto-block-number"] nav button, div[data-block="tripetto-block-multiple-choice"] nav button, div[data-block="tripetto-block-dropdown"] nav button, div[data-block="tripetto-block-paragraph"] nav button {
  width: auto;
  padding: .25rem .75rem .2rem .5rem;
}

div[data-block="tripetto-block-paragraph"] small {
  font-size: .7rem;
  line-height: .9rem;
}

div[data-block="tripetto-block-paragraph"] > div > h2 > span {
  display: none;
}

body {
  visibility: visible;
  margin: 0;
}

body > div {
  width: 700px;
  max-width: calc(100% - 64px);
  margin: 0 auto;
  padding: 0 32px;
}

body > nav {
  background-color: var(--colour-progress, #fff4) !important;
}

@media (width <= 500px) {
  body > nav {
    width: 100vw !important;
    left: 0 !important;
    right: 0 !important;
  }
}

/*# sourceMappingURL=index.443f61b8.css.map */
