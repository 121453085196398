
div[data-block="tripetto-block-text"],
div[data-block="tripetto-block-textarea"],
div[data-block="tripetto-block-number"],
div[data-block="tripetto-block-multiple-choice"],
div[data-block="tripetto-block-dropdown"],
div[data-block="tripetto-block-paragraph"], {
	// background-color: red;

	h2 label span {
		font-weight: 400;
		font-size: 1.4rem;
		line-height: 1.6rem;
	}

	h3 span {
		font-weight: 400;
		font-size: 1.2rem;
		line-height: 1.4rem;	
	}

	button, select, input, textarea {
		width: 100%;
		padding: 0.25rem 0.5rem 0.2rem 0.5rem;
		border-color: #00000066;
		border-radius: 5px;
	}

	nav {
		button {
			width: auto;
			padding: 0.25rem 0.75rem 0.2rem 0.5rem;
		}
	}
}

div[data-block="tripetto-block-paragraph"] {

	small {
		font-size: .7rem;
		line-height: .9rem;
	}

	& > div > h2 > span {
		display: none;
	}

}

body {
	visibility: visible;
	margin: 0;

	& > div {
		width: 700px;
		max-width: calc(100% - 2 * 32px);
		margin: 0 auto;
		padding: 0 32px;
	}

	& > nav {
		background-color: var(--colour-progress, #ffffff44) !important;
	}
}

@media (max-width: 500px) {
	body {
	
		& > nav {
			width: 100vw !important;
			left: 0 !important;
			right: 0 !important;
		}
	}
}